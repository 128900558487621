import React, { useEffect } from "react";
import "./modal.css";
import renderImage from "../../../Sources/renderImage";
import { motion, AnimatePresence } from "framer-motion";
const FormModal = ({ setSubmit, submit }) => {
  const handleCloseModal = () => {
    setSubmit(false);
  };

  useEffect(() => {
    if (submit) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [submit]);
  return (
    <AnimatePresence>
      {submit && (
        <div className="modalCont">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{
              type: "spring",
              damping: 12,
              stiffness: 100,
            }}
            className="form-modal"
          >
            <div className="closeBtn" onClick={handleCloseModal}>
              <img
                draggable={false}
                src="https://cdn-icons-png.flaticon.com/128/1828/1828843.png"
                alt="close"
              />
            </div>
            <div className="cartoon-red">
              <img src={renderImage("group84cropped")} alt="group91" />
            </div>
            <p className="styled-text">
              Thank you
              <br /> for reaching out to us.
            </p>
            <div className="socialmedia">
              <p className="subtext">
                Please follow us and get regular updates on our socials.
              </p>
              <ul>
                {/* <li className="icon">
                  <a href="" target="_blank">
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/733/733547.png"
                      alt="fb"
                    />
                  </a>
                </li> */}
                <li className="icon">
                  <a
                    href="https://www.linkedin.com/showcase/yudu-robotics/posts/?feedView=all"
                    target="_blank"
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/3536/3536505.png"
                      alt="linkedIn"
                    />
                  </a>
                </li>
                <li className="icon">
                  <a
                    href="https://www.instagram.com/theplaycomputer/"
                    target="_blank"
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/2111/2111463.png"
                      alt="insta"
                    />
                  </a>
                </li>
                <li className="icon">
                  <a
                    href="https://www.youtube.com/@playcomputerPeeCee"
                    target="_blank"
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/3938/3938026.png"
                      alt="youtube"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="cartoon-yellow">
              <img src={renderImage("group44")} alt="group91" />
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default FormModal;
