import renderImage from "../../../Sources/renderImage";
import DownloadIcon from "@mui/icons-material/Download";
import productDetails from "../../../Assets/download/PRODUCT DETAILS.pdf";

function ProductsHeader({ text, showDowBtn, otherProductIcon }) {
  return (
    <div>
      <div className="productsPage-header">
        <p className="productsPage-header-text">{text}</p>

        {showDowBtn ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                width: "50%",
                color: "white",
                fontFamily: "TT Hoves Light",
                marginTop: "2em",
              }}
            >
              {/* Explore our curated range: from oscilloscopes and pottery machines
              to laser engravers and workshop tools. Dive into digital
              measurements, optical wonders, and musical delights. Crafted for
              enthusiasts and professionals, ensuring precision and fun. */}
              In addition to our well-known products, we have many other
              robotics and embedded projects currently in development. Our teams
              are working on cutting-edge solutions that push boundaries daily.
              To learn more about these ongoing endeavours and to discuss about
              partnership and customization, please reach out to us.
            </p>
            {/* <a
                        href={productDetails}
                        download="PRODUCT DETAILS"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", marginTop:"30px" }}
                    >
                        <div className="homP-button">
                            <div style={{ marginRight: "10px" }}>DOWNLOAD</div>
                            <DownloadIcon />
                        </div>
                    </a> */}
          </div>
        ) : (
          ""
        )}

        {otherProductIcon ? (
          <img
            className="downloadIcon11"
            style={{ width: "200px" }}
            src={renderImage("downloadIcon")}
            alt="download button"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ProductsHeader;
