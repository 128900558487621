import "./Header.css";
import renderImage from "../../../Sources/renderImage";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";

function Header({ customStyle }) {
    const navigate=useNavigate()

    //take the header style from props
    let newStyle={
        fontSize: "70px",
    }
    if (customStyle) {
        newStyle["color"] = customStyle.menuColor
    }

    return (<div className="header-container">
        <div className="header-logo-container">
            <img onClick={()=>navigate("/home")} style={{ width: "100%", cursor:"pointer" }} src={renderImage("yuduRoboticsLogo")}
                alt="yudu robotics logo"
            />
        </div>

        {/* <div className="header-menu-container">
            <MenuIcon style={newStyle} />
        </div> */}
    </div>)
}

export default Header;