import renderImage from "../../../Sources/renderImage";
import Form from "./Form";


function FromContainer() {
    return (
        <div className="homP-from-container">
            <div className="homP-from-text-container">
                <div className="homP-partner-text">
                <p className="homP-partner" style={{color: "#FEEE40"}}>Partner</p>
                <p className="homP-with-us" style={{color: "#FFFFFF"}}>with us</p></div>
                <img src={renderImage("group192")} alt="group192" className="group192" />
            </div>
            <div className="homP-form-row">
                <div className="homP-form-grid">
                    <div  className="path2419">
                        <img src={renderImage("path2419")} alt="path2419" style={{width: "19px", height: "15px"}} />
                        <p style={{fontSize: "16px", fontFamily: "", color: "#FFFFFF"}}>Email</p>
                        <p style={{color: "#FFFFFF"}}>Our friendly team is here to help</p>
                        <p style={{color: "#FFFFFF"}}>support@yudurobotics.com</p>
                    </div>
                    <div className="path2432">
                        <img src={renderImage("path2432")} alt="path2432" style={{width: "19px", height: "15px"}} />
                        <p style={{fontSize: "16px", fontFamily: "", color: "#FFFFFF"}}>Phone</p>
                        <p style={{color: "#FFFFFF"}}>Mon-Fri from 8am to 5pm</p>
                        <p style={{color: "#FFFFFF"}}>+91 9606755032</p>
                    </div>
                    <div className="path2428"> 
                        <img src={renderImage("path2428")} alt="path2428" style={{width: "15.43px", height: "19.29px"}} />
                        <p style={{fontSize: "16px", fontFamily: "", color: "#FFFFFF"}}>Office</p>
                        <p style={{color: "#FFFFFF", fontWeight: "bold"}}>Come say hello at our office HQ</p>
                        <p style={{color: "#FFFFFF"}}>No.29, Beretena Agrahara, <br/>Next to Play Factory, <br/> Hosur Main Road, <br/> Electronic City, Bangalore, <br/> Karnataka, India</p>
                    </div>
                </div>
                <Form />
            </div>
            
        </div>
    )
}

export default FromContainer;