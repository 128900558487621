//lander page images
import yuduRoboticsLogo from "../Assets/images/Group 66.png";
import group91 from "../Assets/images/pcceeEmoji/Group 91.png";
import group90 from "../Assets/images/pcceeEmoji/Group 90.png";
import group89 from "../Assets/images/pcceeEmoji/Group 89.png";
import group88 from "../Assets/images/pcceeEmoji/Group 88.png";

//homepage button
import playButton from "../Assets/images/button/Group 94.png";
import playButton2 from "../Assets/images/button/Group 161.svg";

//homepage background image
import background1st from "../Assets/images/background/Mask Group 54.png";
import background2nd from "../Assets/images/background/Mask Group 61.png";
import background3rd from "../Assets/images/background/Mask Group 62.png";
import background4th from "../Assets/images/background/Mask Group 80.png";
import background5th from "../Assets/images/background/Group 198.png";
import groupPicture from "../Assets/images/background/Mask Group 79.png"
import group192 from "../Assets/images/background/Group 192.png"

//home page image
import videoImage from "../Assets/images/Mask Group 35.png";
import logo1 from "../Assets/images/logo/Group 56.png";
import yellowLogo from "../Assets/images/logo/Group 58.png";
import homPccee from "../Assets/images/pcceeImage/Group 166.png";
import robotics from "../Assets/images/pcceeImage/Group 168.png";
import otherProdShap from "../Assets/images/pcceeImage/Subtraction 5.png";
import footerBottom from "../Assets/images/Group 178.png";
import group45 from "../Assets/images/pcceeEmoji/Group 45.png";
import group84 from "../Assets/images/pcceeEmoji/Group 84.png";
import group84cropped from "../Assets/images/pcceeEmoji/Group 84_cropped.png";
import group44 from "../Assets/images/pcceeEmoji/Group 44.png";
import group93 from "../Assets/images/pcceeEmoji/Group 93.svg";
import otherProdShapMob from "../Assets/images/pcceeImage/Subtraction 9 (1).svg";
import group199 from "../Assets/images/background/Group 199.png";

//product page button
import downloadIcon from "../Assets/images/button/Group 183.svg";

//products page background
// import proPaBg1 from "../Assets/images/background/Mask Group 56 (1).png";
import proPaBg1 from "../Assets/images/background/Mask Group 72.svg";

//products page image
import zing from "../Assets/images/pcceeImage/Mask Group 58.png";
import crawle from "../Assets/images/pcceeImage/Mask Group 59.png";
import klaw from "../Assets/images/pcceeImage/Mask Group 60.png";
import subtraction29 from "../Assets/images/logo/Subtraction 29.svg";
import subtraction13 from "../Assets/images/logo/Subtraction 13.svg";
import subtraction17 from "../Assets/images/logo/Subtraction 17.svg";
import subtraction22 from "../Assets/images/logo/Subtraction 22.svg";
import group186 from "../Assets/images/products/Group 186.png";
import group187 from "../Assets/images/products/Group 187.png";
import group188 from "../Assets/images/products/Group 188.png";
import group189 from "../Assets/images/products/Group 189.png";
import group190 from "../Assets/images/products/Group 190.png";
//other product image
import oscilloscope from "../Assets/images/products/Image 5.png";
import potteryMachine from "../Assets/images/products/Image 6.png";
import laserMachine from "../Assets/images/products/Image 9.png";
import workShopEquip from "../Assets/images/products/Image 12.png";
import diAnaTool from "../Assets/images/products/Image 13.png";
import optDevice from "../Assets/images/products/Image 14.png";
import musicalIntru from "../Assets/images/products/Image 24.png";
import mats from "../Assets/images/products/Image 19.png";
import eleCom from "../Assets/images/products/Image 20.png";
import storBox from "../Assets/images/products/Image 21.png";
import meTool from "../Assets/images/products/Image 22.png";
import traEdu from "../Assets/images/products/Image 23.png";

//Footer images
import path2419 from "../Assets/images/background/Path 2419.png"
import path2420 from "../Assets/images/background/Path 2420.png"
import path2432 from "../Assets/images/background/Path 2432.png"
import path2428 from "../Assets/images/background/Path 2428.png"

function renderImage(imageName) {
  switch (imageName) {
    case "yuduRoboticsLogo":
      return yuduRoboticsLogo;
    case "group91":
      return group91;
    case "group90":
      return group90;
    case "group89":
      return group89;
    case "group88":
      return group88;
    case "playButton":
      return playButton;
    case "playButton2":
      return playButton2;
    case "background1st":
      return background1st;
    case "videoImage":
      return videoImage;

    case "logo1":
      return logo1;
    case "homPccee":
      return homPccee;
    case "robotics":
      return robotics;
    case "otherProdShap":
      return otherProdShap;
    case "otherProdShapMob":
      return otherProdShapMob;
    case "background2nd":
      return background2nd;
    case "background3rd":
      return background3rd;
    case "yellowLogo":
      return yellowLogo;
    case "footerBottom":
      return footerBottom;
    case "group44":
      return group44;
    case "group45":
      return group45;
    case "group84":
      return group84;
    case "downloadIcon":
      return downloadIcon;
    case "proPaBg1":
      return proPaBg1;
    case "zing":
      return zing;
    case "crawle":
      return crawle;
    case "klaw":
      return klaw;
    case "subtraction29":
      return subtraction29;
    case "oscilloscope":
      return oscilloscope;
    case "potteryMachine":
      return potteryMachine;
    case "laserMachine":
      return laserMachine;
    case "workShopEquip":
      return workShopEquip;
    case "diAnaTool":
      return diAnaTool;
    case "optDevice":
      return optDevice;
    case "musicalIntru":
      return musicalIntru;
    case "mats":
      return mats;
    case "eleCom":
      return eleCom;
    case "storBox":
      return storBox;
    case "meTool":
      return meTool;
    case "traEdu":
      return traEdu;
    case "subtraction13":
      return subtraction13;
    case "subtraction17":
      return subtraction17;
    case "subtraction22":
      return subtraction22;
    case "group93":
      return group93;
    case "group84cropped":
      return group84cropped;

    case "group199":
      return group199;
    case "background4th":
      return background4th;
    case "group186":
      return group186;
    case "group187":
      return group187;
    case "group188":
      return group188;
    case "group189":
      return group189;
    case "group190":
      return group190;
    case "background5th":
      return background5th;

    case "groupPicture":
      return groupPicture;
    case "group192":
      return group192;
    case "path2419":
      return path2419;
    case "path2432":
      return path2432;
    case "path2420":
      return path2420;

    case "path2428":
      return path2428;
  }
}

export default renderImage;
