import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import renderImage from "../../../Sources/renderImage";
import Footer from "../../ReUsableComponents/Footer/Footer";
import Header from "../../ReUsableComponents/Header/Header";
import "./HomePage.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FromContainer from "../../ReUsableComponents/Form/FormContainer";

function HomePage() {
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const playVideo = () => {
    if (videoRef.current === null) {
      return;
    } else {
      const symbol = videoRef.current.src.indexOf("?") > -1 ? "&" : "?";
      videoRef.current.src += symbol + "autoplay=1";
    }
  };

  return (
    <div className="homePage-container">
      <div
        className="tttt"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: "#FEEE40",
        }}
      >
        <Header />

        <div className="hom-1st-content-container">
          <p className="hom-1st-content-container-text ">
          From Playful Robots and Drones to Precision 3D Printers
          </p>

          <div className="video-palay-button" style={{ marginRight: "10vw" }}>
            <img
              src={renderImage("group199")}
              style={{position: 'absolute', top: '150px', left: '69vw'}}
            />
          </div>
        </div>

        <div className="video-container">
          <img className="background1st" src={renderImage("background1st")} />
          <img
            style={{
              position: "absolute",
              top: "-60px",
              left: "69vw",
              zIndex: "-1",
            }}
            src={renderImage("group45")}
          />
          <img
            style={{
              position: "absolute",
              top: "-76px",
              left: "73.5vw",
              zIndex: "-2",
            }}
            src={renderImage("group44")}
          />
          <div className="video-player">
          <iframe
            ref={videoRef}
            src="https://www.youtube.com/embed/0PyGoHZ3TpQ?si=AuRRKwKnZdhEMg-i&rel=0&autoplay=1"
            title="Yudu Robotics - Product Portfolio"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          </div>
          
          <img
            className="group84"
            src={renderImage("group84")}
          />
        </div>

        {/* <div className="video-play-button-d">
          <div
            style={{
              backgroundColor: "#4a1fcc",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "100px",
            }}
          >
            {/* <img src={renderImage("playButton2")} alt="video play button" />
          </div>
        </div> */}
      </div>

      <div style={{ backgroundColor: "#4A1FCC", paddingTop: "160px" }}>
         <div className="logo1-container">
          <img src={renderImage("group186")} alt="zing logo" className="zing-logo" />
          <p className="logo1-container-text">We&apos;re a product innovation company based in Bangalore, and we&apos;ve been crafting cutting-edge tech for <span style={{color: "#FEEE40"}}>over a decade</span>!</p>
        </div>

        <div className="logo2-container">
          <p className="logo2-container-text">From Smart STEM Toys to robots, 3D printers, and drones — <span>we build it all.</span></p>
          <img src={renderImage("group187")} alt="crawl-e logo" className="crawl-e-logo"/>
        </div>

        <div className="logo3-container">
            <img src={renderImage("group188")} alt="roboki logo"/>
            <p className="logo3-container-text">If our products excite you, or if you have a product idea you&apos;d like us to customize and manufacture, reach out! Let&apos;s create something awesome together.</p>
        </div>

        <div className="logo4-container">
            <img src={renderImage("group189")} alt="klaw logo" className="klaw-logo" />
            <img src={renderImage("group190")} alt="peecee logo" className="peecee-logo"/>
        </div>
        <div>
          <img src={renderImage("background5th")} alt="background" className="background5th" style={{}}/>
        </div>

        <div className="meet-the-team-container">
          <p className="meet-the-team-container-text">Meet the People <br/> You&apos;ll be Working With</p>
          <img src={renderImage("groupPicture")} alt="group picture" className="group-picture"/>
        </div>
{/*
        <div className="text">
          <p className="text-where" style={{ marginLeft: "19vw" }}>
            Where
          </p>
          <p style={{ marginLeft: "28vw", fontFamily: "Piepie Regular" }}>
            robots
          </p>
          <p style={{ marginLeft: "13vw" }}>Rule</p>
        </div>

        <div style={{ position: "relative" }}>
          <img className="homPccee" src={renderImage("homPccee")} />
        </div>

        <div className="peecee-about-container">
          <p className="homP-text-head">PEE-CEE</p>
          <p className="homP-text-body">
            The cute codable computer with unlimited external sensor
            compatibility. Combine PeeCee with Plode, the ultimate coding
            software and bring your imagination to life!
          </p>
          <div
            onClick={() => window.open("https://www.playcomputer.org/")}
            className="homP-button"
          >
            <p>EXPLORE</p>
            <ArrowForwardIcon />
          </div>
        </div>

        <div className="robotics-container">
          <div>
            <img className="robotics" src={renderImage("robotics")} />
          </div>
          <div className="robotics-about-container">
            <p className="homP-text-head">ROBOTICS</p>
            <p className="homP-text-body">
              Meet our RoboBuddies family, a range of friendly robots that teach
              coding, problem-solving, and critical thinking in a fun and
              interactive way.
            </p>
            <div onClick={() => navigate("/products")} className="homP-button">
              <p>EXPLORE</p>
              <ArrowForwardIcon />
            </div>
          </div>
        </div>

        <div className="otherProd-container">
          <div>
            <img className="otherProdShap" src={renderImage("otherProdShap")} />
            <img className="group93" src={renderImage("group93")} />
            <div className="otherProd-about-container">
              <p className="homP-text-head">OTHER PRODUCTS</p>
              <p className="homP-text-body">
                Explore our curated range: from oscilloscopes and pottery
                machines to laser engravers and workshop tools. Crafted for
                enthusiasts and professionals, ensuring precision and fun.
              </p>
            </div>
          </div>

          <div
            onClick={() => navigate("/products")}
            style={{ marginRight: "15vw", marginBottom: "100px" }}
            className="homP-button"
          >
            <p>EXPLORE</p>
            <ArrowForwardIcon />
          </div>
        </div>

        <div className="otherProd-container-mob">
          <div>
            <img
              className="otherProdShap-mob"
              src={renderImage("otherProdShapMob")}
            />
            

            <div className="otherProd-about-container">
              <p className="homP-text-head">OTHER PRODUCTS</p>
              <p className="homP-text-body">
                Explore our curated range: from oscilloscopes and pottery
                machines to laser engravers and workshop tools. Crafted for
                enthusiasts and professionals, ensuring precision and fun.
              </p>
            </div>

            <div
              onClick={() => navigate("/products")}
              style={{ marginRight: "15vw" }}
              className="homP-button homP-button-mob"
            >
              <p>EXPLORE</p>
              <ArrowForwardIcon />
            </div>
            <img className="group93" src={renderImage("group93")} />
          </div>
        </div>*/}
      </div> 
      {/* style={{position: "absolute"}} */}
      <div style={{ backgroundColor: "#4A1FCC", paddingTop: "100px" }}>
        <div>
          {/* <img
            style={{ position: "relative", top: "10px", width: "100vw" }}
            src={renderImage("background2nd")}
          /> */}
        </div>

        <FromContainer />

        <div>
          {/* <img style={{ width: "100vw" }} src={renderImage("background4th")} /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
