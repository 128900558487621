
import './App.css';
import LandingPage from './Components/Pages/LandingPage/LandingPage';
import HomePage from './Components/Pages/MainPage/HomePage';
import { Routes, Route, useLocation } from "react-router-dom"
import ProductsPage from './Components/Pages/ProductsPage/ProductsPage';
import WhatsappLogo from './Components/ReUsableComponents/WhatsappLogo/WhatsappLogo';
import { useLayoutEffect } from 'react';


const Wrapper = ({ children }) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return children
}

function App() {
  return (
    <div className="App">
      <Wrapper >
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/products" element={<ProductsPage />} />
        </Routes>

        <WhatsappLogo />
      </Wrapper>
    </div>
  );
}

export default App;
