import renderImage from "../../../Sources/renderImage";
import Footer from "../../ReUsableComponents/Footer/Footer";
import FromContainer from "../../ReUsableComponents/Form/FormContainer";
import Header from "../../ReUsableComponents/Header/Header";
import ProductsHeader from "./ProductsHeader";
import "./ProductsPage.css";
import { otherProduct } from "./productDetails";

function ProductsPage() {
  return (
    <div style={{ backgroundColor: "#26357f" }}>
      <Header customStyle={{ menuColor: "#FFD804" }} />

      {/* ---------------------------robotics product---------------------------- */}
      <ProductsHeader
        showDowBtn={false}
        otherProductIcon={true}
        text="Robotics"
      />
      <div className="robotics-product-container">
        <img className="group61-product" src={renderImage("background2nd")} />
        <div
          className="products-details-container"
          style={{
            backgroundColor: "#FFFBE6",
            paddingTop: "60px",
            position: "relative",
          }}
        >
          <div className="products-details-container-row">
            <div className="zing-container">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <img src={renderImage("zing")} alt="Zing" />
              </div>
              <p className="products-details-header">ZING</p>
              <p className="products-details-text">
                Zing is an interactive, AI-powered device with a durable
                aluminium build, offering a blend of entertainment and
                customization options. Equipped with IoT capabilities, it
                delivers a rich user experience, controllable via the Plode app.
              </p>
            </div>
            <div className="klaw-container">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <img src={renderImage("klaw")} alt="Klaw" />
              </div>
              <p className="products-details-header">KLAW</p>
              <p className="products-details-text">
                Klaw is an interactive, AI-powered Robotic Arm with a durable
                aluminium build, offering a blend of engagement and learning.
                Equipped with IoT capabilities, it delivers a amazing user
                experience and is controllable via the Plode app.
              </p>
            </div>
          </div>
          <div className="products-details-container-row">
            <div className="crawle-container">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="crawle"
                  src={renderImage("crawle")}
                  alt="Crawl-E"
                />
              </div>
              <p className="products-details-header">CRAWL-E</p>
              <p className="products-details-text">
                Crawl-E is a versatile, six-legged AI device, designed for
                engagement and adaptability. With a robust aluminium frame, IoT
                integration, it offers a dynamic user experience, all
                controllable via the Plode app.
              </p>
            </div>
          </div>

          <img className="subtraction29" src={renderImage("subtraction29")} />
        </div>
      </div>

      {/* ------------------------other product dom element------------------- */}
      <ProductsHeader
        showDowBtn={true}
        otherProductIcon={false}
        text="Other Products"
      />
      <div className="robotics-product-container">
        <img className="proPaBg1" src={renderImage("proPaBg1")} />
        <div
          className="products-details-container"
          style={{
            backgroundColor: "#FFFBE6",
            paddingTop: "60px",
            position: "relative",
          }}
        >
          {/*} {otherProduct.map((product, ind) => {
          return (<div key={ind} className="products-details-container-row">
            <div className="p-container">
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={renderImage(product[0].img)} alt={product[0].header} />
              </div>
              <p className="products-details-header">{product[0].header}</p>
              <p className="products-details-text">{product[0].text}</p>
            </div>
            <div className="p-container">
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={renderImage(product[1].img)} alt={product[1].header} />
              </div>
              <p className="products-details-header">{product[1].header}</p>
              <p className="products-details-text">{product[1].text}</p>
            </div>
          </div>)
        })} 

        <img className="subtraction13" src={renderImage("subtraction13")} />
        <img className="subtraction17" src={renderImage("subtraction17")} />
        <img className="subtraction22" src={renderImage("subtraction22")} />
      */}
          <FromContainer />
        </div>
      </div>

      <div>
        <img style={{ width: "100vw" }} src={renderImage("background3rd")} />
      </div>

      {/* ------------------------------------footer dom element---------------------------- */}
      <Footer />
    </div>
  );
}

export default ProductsPage;
