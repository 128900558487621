
import { useNavigate } from "react-router-dom";
import renderImage from "../../../Sources/renderImage";
import "./LandingPage.css";


function LandingPage() {
    const navigate=useNavigate();

    return (<div className="landing-page-main">
        <div style={{ display: "flex", justifyContent: "center", paddingTop:"20px" }}>
            <img src={renderImage("yuduRoboticsLogo")}
                alt="yudu robotics logo"
            />
        </div>

        <div className="lanP-content-container" >
            <div className="pccee-emoji-div" style={{ display: "flex", justifyContent: "center", alignItems:"end", marginTop: '20px' }}>
                <img src={renderImage("group91")}
                    alt="pccee 1"
                />
                <img src={renderImage("group89")}
                    alt="pccee 2"
                />
                <img src={renderImage("group88")}
                    alt="pccee 3"
                />
                <img src={renderImage("group90")}
                    alt="pccee 4"
                />
            </div>
            <div className="lanP-text-container" >
                <p>
                YOU'RE ABOUT TO ENTER THE ONLY SOURCE OF CRAZY ROBOTICS AND STEM TOYS FOR KIDS YOU WILL EVER NEED 
                </p>
            </div>

            <div onClick={()=>navigate('/home')} className="enter-button">
                <p>ENTER</p>
            </div>
        </div>
    </div>)
}

export default LandingPage;