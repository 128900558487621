import renderImage from "../../../Sources/renderImage";
import "./Footer.css";

function Footer() {
    return (<div className="footer-container">
        {/* <div className="footer-content-container">
            <div>
                <img src={renderImage("yellowLogo")} alt="yudu Robotics logo" />
            </div>
            <div className="footer-text-container">
                <p>No. 29, Beratena Agrahara, Next to Play Factory,Hosur Main Road, Electronic City Post, Bangalore – 560100, Karnataka, India</p>
                <p>Mob</p>
            </div> 
        </div> */}

        <div className="footer-content-container" >
            <img style={{width:'100vw'}} src={renderImage("background4th")}/>
            <img style={{width:'100vw'}} src={renderImage("footerBottom")} />
        </div>
    </div>)
}

export default Footer;